import React, { useState } from "react";
import Menu from "../Menu/Menu";
import { API_URL } from "../../Config";
import { useNavigate } from "react-router-dom";

const AddUser = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    address: "",
    city: "",
    postcode: "",
    password: "",
    role: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      const numericValue = value.replace(/\D/g, "");
      const truncatedValue = numericValue.slice(0, 10);
      setFormData({
        ...formData,
        [name]: truncatedValue,
      });
    } else if (name === "postcode") {
      if (/^\d{0,6}$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(API_URL + "/addUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (response.ok) {
        alert("User added successfully");
        navigate("/UserDatabase");
      } else {
        alert(`Failed to add user: ${result.error}`);
      }
    } catch (error) {
      console.error("Error adding user:", error);
      alert("Failed to add user");
    }
  };

  return (
    <div className="text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10">
      <Menu />
      <div className="lg:ml-[8%] p-[3%] md:p-[2%]">
        <p className="mb-[2%] font-semibold">Add User</p>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 lg:grid-cols-3 items-center">
            <div className="m-[2%] lg:mx-[5%]">
              <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
                First Name
              </p>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
                required
              />
            </div>

            <div className="m-[2%] lg:mx-[5%]">
              <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
                Last Name
              </p>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
                required
              />
            </div>

            <div className="m-[2%] lg:mx-[5%]">
              <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
                Email
              </p>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
                required
              />
            </div>

            <div className="m-[2%] lg:mx-[5%]">
              <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
                Phone Number
              </p>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
                required
              />
            </div>

            <div className="m-[2%] lg:mx-[5%]">
              <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
                Address
              </p>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              />
            </div>

            <div className="m-[2%] lg:mx-[5%]">
              <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
                Post Code/ ZIP
              </p>
              <input
                type="text"
                name="postcode"
                value={formData.postcode}
                onChange={handleChange}
                className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              />
            </div>

            <div className="m-[2%] lg:mx-[5%]">
              <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
                Country
              </p>
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
                required
              />
            </div>

            <div className="m-[2%] lg:mx-[5%]">
              <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
                City
              </p>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
                required
              />
            </div>

            <div className="m-[2%] lg:mx-[5%]">
              <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
                Password
              </p>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
                required
              />
            </div>

            <div className="m-[2%] lg:mx-[5%]">
              <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
                Role
              </p>
              <select
                className="bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
                name="role"
                value={formData.role}
                onChange={handleChange}
                style={{ appearance: "none" }}
                required
              >
                <option value="" disabled hidden name="payment_status">
                  Select
                </option>
                <option value="User">User</option>
                <option value="Agent">Agent</option>
                <option value="Admin">Admin</option>
                <option value="Management">Management</option>
              </select>
            </div>
          </div>

          <div className="flex my-[3%] lg:my-[1%] ml-[2%]">
            <button
              type="button"
              className="cancel_btn"
              onClick={() => navigate("/Dashboard")}
            >
              Cancel
            </button>
            <button type="submit" className="done_btn">
              Done
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUser;
