import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./Dashboard.css";
import Menu from "../Menu/Menu";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { FaWpforms } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../Config";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Table } from "antd";
// import axios from 'axios';
import { TbMailShare } from "react-icons/tb";
import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Dashboard = () => {
  const navigate = useNavigate();
  const [isHandle_Delete, setHandle_Delete] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [users, setUsers] = useState([]);
  const [villas, setVillas] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");

  const fetchusers = useCallback(async () => {
    try {
      const response = await fetch(API_URL + "/userDetails", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = await response.json();

      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, []);

  const fetchRooms = useCallback(async () => {
    try {
      const response = await fetch(API_URL + "/roomsController");
      const data = await response.json();
      setVillas(data);
    } catch (error) {
      console.error("Error fetching rooms:", error);
    }
  }, []);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await fetch(API_URL + "/reservationDetails", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            role: "admin",
          }),
        });
        let data = await response.json();

        const today = new Date().toISOString().split("T")[0];
        const filteredBookings = data.filter(
          (booking) => booking.checkIn >= today
        );
        setBookings(filteredBookings);
        localStorage.setItem("bookings", JSON.stringify(filteredBookings));
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    };

    fetchBookings();
    fetchusers();
    fetchRooms();
  }, [fetchusers, fetchRooms]);

  const closeForm_Delete = () => {
    setHandle_Delete(false);
  };

  const getTodaysBookingsCount = () => {
    const today = new Date().toISOString().split("T")[0];
    return bookings.filter((booking) => booking.checkIn === today).length;
  };

  const tableTitles = [
    " ",
    "Booking ID",
    "Full Name",
    "Email",
    "Property",
    "Check In",
    "Check Out",
    "Amount",
    "Status",
    "Booking Date",
    "Portal",
    "Action",
  ];

  const getStatusCellStyle = (status) => {
    switch (status) {
      case "Confirmed":
        return { color: "#36993F", fontWeight: 500 };
      case "Partial":
        return { color: "#F3894E", fontWeight: 500 };
      case "Cancelled":
        return { color: "#C12929", fontWeight: 500 };
      case "Pending":
        return { color: "#C0BB34", fontWeight: 500 };
      case "Direct Bank":
        return { color: "#2D949A", fontWeight: 500 };
      default:
        return {};
    }
  };

  const isWithinDateRange = (date, startDate, endDate) => {
    if (!startDate && !endDate) return true;
    if (!startDate) return new Date(date) <= new Date(endDate);
    if (!endDate) return new Date(date) >= new Date(startDate);
    return (
      new Date(date) >= new Date(startDate) &&
      new Date(date) <= new Date(endDate)
    );
  };

  const filteredBookings = bookings
    .filter((i) => {
      const searchQueryLower = searchQuery.toLowerCase();
      i.name = i.name ? i.name.toString() : "";
      i.id = i.id ? i.id.toString() : "";
      i.villaName = i.villaName ? i.villaName.toString() : "";
      i.total = i.total ? i.total.toString() : "";
      i.email = i.email ? i.email.toString() : "";
      i.checkIn = i.checkIn ? i.checkIn.toString() : "";
      i.checkOut = i.checkOut ? i.checkOut.toString() : "";
      i.payment_status = i.payment_status ? i.payment_status.toString() : "";
      i.booking_portal = i.booking_portal ? i.booking_portal.toString() : "";

      return (
        (searchQuery === "" ||
          i.name.toLowerCase().includes(searchQueryLower) ||
          i.id.toString().includes(searchQueryLower) ||
          i.villaName.toLowerCase().includes(searchQueryLower) ||
          i.total.toString().includes(searchQueryLower) ||
          i.payment_status.toString().includes(searchQueryLower) ||
          i.booking_portal.toString().includes(searchQueryLower) ||
          i.email.toLowerCase().includes(searchQueryLower)) &&
        (isWithinDateRange(i.checkIn, checkInDate, checkOutDate) ||
          isWithinDateRange(i.checkOut, checkInDate, checkOutDate))
      );
    })
    .sort((a, b) => {
      const today = new Date().toISOString().split("T")[0];
      const dateA = a.checkIn;
      const dateB = b.checkIn;

      if (dateA === today && dateB !== today) return -1;
      if (dateB === today && dateA !== today) return 1;
      return dateA.localeCompare(dateB);
    });

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 20;

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows =
    filteredBookings.length > 0
      ? filteredBookings.slice(indexOfFirstRow, indexOfLastRow)
      : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const Pagination = ({ rowsPerPage, totalRows, paginate, currentPage }) => {
    const totalPages = Math.ceil(totalRows / rowsPerPage);

    return (
      <nav className="flex justify-end my-[2%]">
        <ul className="flex items-center">
          {currentPage > 1 && (
            <li className="mx-1">
              <button
                onClick={() => paginate(currentPage - 1)}
                className="p-1 bg-gray-200 text-xs rounded-full"
              >
                <MdKeyboardArrowLeft />
              </button>
            </li>
          )}
          <li className="mx-1">
            <span className="px-2 py-2 text-xs">
              {currentPage} / {totalPages}
            </span>
          </li>
          {currentPage < totalPages && (
            <li className="mx-1">
              <button
                onClick={() => paginate(currentPage + 1)}
                className="p-1 bg-gray-200 text-xs rounded-full"
              >
                <MdKeyboardArrowRight />
              </button>
            </li>
          )}
        </ul>
      </nav>
    );
  };

  const [fileName, setFileName] = useState("Total Revenue");
  const handleExport = () => {
    const userInput = window.prompt("Enter file name:", "Total Revenue");
    if (userInput) {
      setFileName(userInput.trim());
      handleExportConfirm();
    }
  };

  const handleExportConfirm = () => {
    const exportData = currentRows;

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });

    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    saveAs(blob, `${fileName}.xlsx`);
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const handleEditBooking = (row) => {
    navigate("/EditBooking", { state: { villaDetails: row } });
  };

  const [loading, setLoading] = useState(false);

  const handleEmail = (id) => {
    const numericId = Number(id);
    setLoading(true);

    setTimeout(() => {
      fetch(API_URL + "/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: numericId }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setLoading(false);
          setTimeout(() => alert("Email sent successfully!"), 0);
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Failed to send email.");
          setLoading(false);
        });
    }, 0);
  };

  const [isPopup_forBox1, setPopup_forBox1] = useState(false);

  const openPopup_forBox1 = () => {
    setPopup_forBox1(true);
  };

  const closePopup_forBox1 = () => {
    setPopup_forBox1(false);
  };

  const Columns_forBox1 = [
    {
      title: <div style={{ color: "black" }}>Property Name</div>,
      dataIndex: "PropertyName",
      key: "PropertyName",
      width: "50%",
    },
    {
      title: <div style={{ color: "black" }}>Property Type</div>,
      dataIndex: "PropertyType",
      key: "PropertyType",
      width: "50%",
    },
  ];

  const data_forBox1 = useMemo(() => {
    return villas && villas.length > 0
      ? villas.map((item, index) => {
          return {
            key: index.toString(),
            PropertyName: item.villaName || "N/A",
            PropertyType: item.property_type || "N/A",
          };
        })
      : [];
  }, [villas]);

  const [isPopup_forBox2, setPopup_forBox2] = useState(false);

  const openPopup_forBox2 = () => {
    setPopup_forBox2(true);
  };

  const closePopup_forBox2 = () => {
    setPopup_forBox2(false);
  };

  const Columns_forBox2 = [
    {
      title: <div style={{ color: "black" }}>Property Name</div>,
      dataIndex: "PropertyName",
      key: "PropertyName",
      // width: '50%',
    },
    {
      title: <div style={{ color: "black" }}>Check-In</div>,
      dataIndex: "CheckIn",
      key: "CheckIn",
      // width: '50%',
    },
    {
      title: <div style={{ color: "black" }}>Check-Out</div>,
      dataIndex: "CheckOut",
      key: "CheckOut",
      // width: '50%',
    },
  ];

  const data_forBox2 = useMemo(() => {
    return bookings && bookings.length > 0
      ? bookings
          .slice()
          .sort((a, b) => new Date(a.checkIn) - new Date(b.checkIn))
          .map((item, index) => {
            return {
              key: index.toString(),
              PropertyName: item.villaName || "N/A",
              CheckIn: item.checkIn
                ? new Date(item.checkIn).toLocaleDateString("en-GB")
                : "N/A",
              CheckOut: item.checkOut
                ? new Date(item.checkOut).toLocaleDateString("en-GB")
                : "N/A",
            };
          })
      : [];
  }, [bookings]);

  const [isPopup_forBox3, setPopup_forBox3] = useState(false);

  const openPopup_forBox3 = () => {
    setPopup_forBox3(true);
  };

  const closePopup_forBox3 = () => {
    setPopup_forBox3(false);
  };

  const Columns_forBox3 = [
    {
      title: <div style={{ color: "black" }}>Name</div>,
      dataIndex: "Name",
      key: "Name",
      // width: '50%',
    },
    {
      title: <div style={{ color: "black" }}>Role</div>,
      dataIndex: "Role",
      key: "Role",
      // width: '50%',
    },
    {
      title: <div style={{ color: "black" }}>Phone Number</div>,
      dataIndex: "PhoneNumber",
      key: "PhoneNumber",
      // width: '20%',
    },
    {
      title: <div style={{ color: "black" }}>Email</div>,
      dataIndex: "Email",
      key: "Email",
      width: "40%",
    },
  ];

  const data_forBox3 = useMemo(() => {
    return users && users.length > 0
      ? users.map((item, index) => {
          return {
            key: index.toString(),
            Name: `${item.firstName} ${item.lastName}` || "N/A",
            Role: item.role || "N/A",
            PhoneNumber: item.phone || "N/A",
            Email: item.email || "N/A",
          };
        })
      : [];
  }, [users]);

  const [isPopup_forBox4, setPopup_forBox4] = useState(false);

  const openPopup_forBox4 = () => {
    setPopup_forBox4(true);
  };

  const closePopup_forBox4 = () => {
    setPopup_forBox4(false);
  };

  const Columns_forBox4 = [
    {
      title: <div style={{ color: "black" }}>Property Name</div>,
      dataIndex: "PropertyName",
      key: "PropertyName",
      // width: '50%',
    },
    {
      title: <div style={{ color: "black" }}>Check-In</div>,
      dataIndex: "CheckIn",
      key: "CheckIn",
      // width: '50%',
    },
    {
      title: <div style={{ color: "black" }}>Check-Out</div>,
      dataIndex: "CheckOut",
      key: "CheckOut",
      // width: '50%',
    },
  ];

  const data_forBox4 = useMemo(() => {
    const today = new Date().toISOString().split("T")[0];

    return bookings && bookings.length > 0
      ? bookings
          .filter((item) => item.checkIn === today)
          .map((item, index) => ({
            key: index.toString(),
            PropertyName: item.villaName || "N/A",
            CheckIn: item.checkIn
              ? new Date(item.checkIn).toLocaleDateString("en-GB")
              : "N/A",
            CheckOut: item.checkOut
              ? new Date(item.checkOut).toLocaleDateString("en-GB")
              : "N/A",
          }))
      : [];
  }, [bookings]);

  const [selectedRows, setSelectedRows] = useState([]);

  const handleCheckboxChange = (id, page) => {
    setSelectedRows((prevSelected) => {
      if (prevSelected.some((row) => row.id === id && row.page === page)) {
        return prevSelected.filter(
          (row) => !(row.id === id && row.page === page)
        );
      } else {
        return [...prevSelected, { id, page }];
      }
    });
  };

  const isRowSelected = (id, page) => {
    return selectedRows.some((row) => row.id === id && row.page === page);
  };

  const handleDeleteRows = async (event) => {
    event.preventDefault();

    const rowsToDelete = selectedRows.map((row) => row.id);

    setTimeout(async () => {
      try {
        const response = await fetch(
          `${API_URL}/delete-booking?bookingId=${rowsToDelete.join(",")}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          alert(`Deleted booking successfully: ${rowsToDelete.join(", ")}`);
          window.location.reload();
        } else {
          alert("Failed to delete booking.");
        }
      } catch (error) {
        console.error("Error deleting rows:", error);
      }
    });
  };

  const [isPopup_forDelete, setPopup_forDelete] = useState(false);

  const openPopup_forDelete = () => {
    setPopup_forDelete(true);
  };

  const closePopup_forDelete = () => {
    setPopup_forDelete(false);
  };

  const getCurrentMonthDates = () => {
    const now = new Date();
    const firstDay_donutChart = new Date(now.getFullYear(), now.getMonth(), 2);
    const lastDay_donutChart = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      1
    );
    return {
      start_donutChart: firstDay_donutChart.toISOString().split("T")[0], // YYYY-MM-DD format
      end_donutChart: lastDay_donutChart.toISOString().split("T")[0],
    };
  };

  const { start_donutChart, end_donutChart } = getCurrentMonthDates();

  const [startDate_donutChart, setStartDate_donutChart] =
    useState(start_donutChart);
  const [endDate_donutChart, setEndDate_donutChart] = useState(end_donutChart);
  const [selectedCategory, setSelectedCategory] =
    useState("Most Popular Villa");

  const [donutData, setDonutData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          "#E8B4B4",
          "#D89C9C",
          "#F3D89C",
          "#DFC3A4",
          "#A9D3A5",
          "#7BB5B2",
          "#82B1D1",
          "#9D91C8",
          "#B99ACD",
          "#D5A3B6",
          "#C2A18B",
          "#D6BDA2",
        ],
        hoverBackgroundColor: [
          "#E8B4B4",
          "#D89C9C",
          "#F3D89C",
          "#DFC3A4",
          "#A9D3A5",
          "#7BB5B2",
          "#82B1D1",
          "#9D91C8",
          "#B99ACD",
          "#D5A3B6",
          "#C2A18B",
          "#D6BDA2",
        ],
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(API_URL + "/reservationDetails", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ role: "admin" }),
        });

        if (!response.ok) throw new Error("Failed to fetch data");
        const reservations = await response.json();

        if (!startDate_donutChart || !endDate_donutChart) return;

        const start = new Date(startDate_donutChart);
        const end = new Date(endDate_donutChart);
        end.setHours(23, 59, 59, 999);

        let dataMap = {};

        reservations.forEach((res) => {
          const checkInDate = new Date(res.checkIn);
          if (checkInDate < start || checkInDate > end) return;

          if (selectedCategory === "Most Popular Villa") {
            const nights = Number(res.nights) || 0;
            dataMap[res.villaName] = (dataMap[res.villaName] || 0) + nights + 1;
          } else if (selectedCategory === "Booking Type") {
            dataMap[res.booking_portal] =
              (dataMap[res.booking_portal] || 0) + 1;
          } else if (selectedCategory === "Revenue") {
            dataMap[res.villaName] =
              (dataMap[res.villaName] || 0) + Number(res.total);
          }
        });

        setDonutData({
          labels: Object.keys(dataMap),
          datasets: [
            {
              data: Object.values(dataMap),
              backgroundColor: [
                "#E8B4B4",
                "#D89C9C",
                "#F3D89C",
                "#DFC3A4",
                "#A9D3A5",
                "#7BB5B2",
                "#82B1D1",
                "#9D91C8",
                "#B99ACD",
                "#D5A3B6",
                "#C2A18B",
                "#D6BDA2",
              ],
              hoverBackgroundColor: [
                "#E8B4B4",
                "#D89C9C",
                "#F3D89C",
                "#DFC3A4",
                "#A9D3A5",
                "#7BB5B2",
                "#82B1D1",
                "#9D91C8",
                "#B99ACD",
                "#D5A3B6",
                "#C2A18B",
                "#D6BDA2",
              ],
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching reservation details:", error);
      }
    };

    fetchData();
  }, [startDate_donutChart, endDate_donutChart, selectedCategory]);

  const donutOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw;
            if (selectedCategory === "Revenue") {
              return `₹ ${value.toLocaleString("en-IN")}`; // Rupee format
            }
            return `${value} %`;
          },
        },
      },
      legend: { position: "left" },
    },
  };

  const [barData, setBarData] = useState({
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Number of Bookings",
        data: Array(12).fill(0),
        backgroundColor: "#C2A18B",

        borderRadius: 10,
      },
      {
        label: "Total Sales (₹)",
        data: Array(12).fill(0),
        backgroundColor: "#D6BDA2",
        borderRadius: 10,
      },
    ],
  });

  const [availableYears_barChart, setAvailableYears_barChart] = useState([]);
  const [selectedYear_barChart, setSelectedYear_barChart] = useState(
    new Date().getFullYear()
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching reservation details...");
        const response = await fetch(API_URL + "/reservationDetails", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ role: "admin" }),
        });

        if (!response.ok)
          throw new Error(`Failed to fetch data: ${response.status}`);

        const reservations = await response.json();

        if (!Array.isArray(reservations) || reservations.length === 0) {
          console.warn("No reservations found.");
          return;
        }

        const years = [
          ...new Set(
            reservations
              .map((res) => {
                const year = res.checkIn
                  ? new Date(res.checkIn).getFullYear()
                  : null;
                return isNaN(year) ? null : year;
              })
              .filter((year) => year !== null)
          ),
        ].sort((a, b) => b - a);

        setAvailableYears_barChart(years);

        updateData_barChart(reservations, selectedYear_barChart);
      } catch (error) {
        console.error("Error fetching reservation details:", error);
      }
    };

    fetchData();
  }, [selectedYear_barChart]);

  const updateData_barChart = (reservations, year) => {
    const monthlyBookings = Array(12).fill(0);
    const monthlySales = Array(12).fill(0);

    reservations.forEach((res) => {
      const bookingYear = new Date(res.checkIn).getFullYear();
      if (bookingYear === year) {
        const monthIndex = new Date(res.checkIn).getMonth();
        monthlyBookings[monthIndex] += 1;
        monthlySales[monthIndex] += Number(res.total) || 0;
      }
    });

    const maxBookings = Math.max(...monthlyBookings);
    const highlightColor = "#6F5648";

    setBarData({
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Number of Bookings",
          data: monthlyBookings,
          backgroundColor: monthlyBookings.map((count) =>
            count === maxBookings ? highlightColor : "#A18D77"
          ),
          borderRadius: 5,
          barThickness: 25,
        },
        {
          label: "Total Sales (₹)",
          data: monthlySales,
          backgroundColor: monthlySales.map(() => "#D4B996"),
          borderRadius: 5,
          barThickness: 25,
        },
      ],
    });
  };

  const barOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Analysis",
      },
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        barPercentage: 0.8, // spacing between bars
        categoryPercentage: 0.2, // spacing within groups
        stacked: false,
        grid: { display: false },
      },
      y: {
        // min: 0,
        // max: 100,
        stacked: false,
        ticks: { callback: (value) => value + "" },
        grid: { display: false },
      },
    },
  };

  const [openRowId, setOpenRowId] = useState(null);

  const openOptions = (rowId) => {
    setOpenRowId((prevRowId) => (prevRowId === rowId ? null : rowId));
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenRowId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const userRole = localStorage.getItem("userRole");

  const handleFeedbackForm = (row) => {
    navigate("/FeedbackForm", {
      state: {
        name: row.name,
        phone: row.phone,
        email: row.email,
        villaName: row.villaName,
      },
    });
  };

  return (
    <div className="text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10">
      <Menu />
      <div className="lg:ml-[8%] p-[3%] md:p-[2%]">
        <div className="grid grid-cols-2 lg:grid-cols-4">
          <div
            className="bg-[#877D6F] p-[3%] m-[2%] text-white rounded-lg hover:cursor-pointer"
            onClick={openPopup_forBox1}
          >
            <p className="text-xl md:text-2xl 2xl:text-3xl font-semibold">
              {villas.length > 0 ? villas.length : 0}
            </p>
            <p className="font-light">Total Villas & Apartments</p>
          </div>
          {isPopup_forBox1 && (
            <>
              <div
                className="overlay_Dashboard"
                onClick={closePopup_forBox1}
              ></div>
              <div className="popup_form_Dashboard">
                <span className="close_Dashboard" onClick={closePopup_forBox1}>
                  &times;
                </span>
                <p>
                  <b>Total Villas & Apartments</b>
                </p>
                <br />
                <form>
                  <div>
                    <Table
                      columns={Columns_forBox1}
                      dataSource={data_forBox1}
                      scroll={{ y: 400 }}
                      pagination={false}
                    />
                    <br />
                    <br />
                  </div>
                </form>
              </div>
            </>
          )}
          <div
            className="bg-[#877D6F] p-[3%] m-[2%] text-white rounded-lg hover:cursor-pointer"
            onClick={openPopup_forBox2}
          >
            <p className="text-xl md:text-2xl 2xl:text-3xl font-semibold">
              {bookings.length > 0 ? bookings.length : 0}
            </p>
            <p className="font-light">Upcoming Bookings</p>
          </div>
          {isPopup_forBox2 && (
            <>
              <div
                className="overlay_Dashboard"
                onClick={closePopup_forBox2}
              ></div>
              <div className="popup_form_Dashboard">
                <span className="close_Dashboard" onClick={closePopup_forBox2}>
                  &times;
                </span>
                <p>
                  <b>Upcoming Bookings</b>
                </p>
                <br />
                <form>
                  <div>
                    <Table
                      columns={Columns_forBox2}
                      dataSource={data_forBox2}
                      scroll={{ y: 400 }}
                      pagination={false}
                    />
                    <br />
                    <br />
                  </div>
                </form>
              </div>
            </>
          )}
          <div
            className="bg-[#877D6F] p-[3%] m-[2%] text-white rounded-lg hover:cursor-pointer"
            onClick={openPopup_forBox3}
          >
            <p className="text-xl md:text-2xl 2xl:text-3xl font-semibold">
              {users.length > 0 ? users.length : 0}
            </p>
            <p className="font-light">Total Users</p>
          </div>
          {isPopup_forBox3 && (
            <>
              <div
                className="overlay_Dashboard"
                onClick={closePopup_forBox3}
              ></div>
              <div className="popup_form_Dashboard">
                <span className="close_Dashboard" onClick={closePopup_forBox3}>
                  &times;
                </span>
                <p>
                  <b>Total Users</b>
                </p>
                <br />
                <form>
                  <div>
                    <Table
                      columns={Columns_forBox3}
                      dataSource={data_forBox3}
                      scroll={{ y: 400 }}
                      pagination={false}
                    />
                    <br />
                    <br />
                  </div>
                </form>
              </div>
            </>
          )}
          <div
            className="bg-[#877D6F] p-[3%] m-[2%] text-white rounded-lg hover:cursor-pointer"
            onClick={openPopup_forBox4}
          >
            <p className="text-xl md:text-2xl 2xl:text-3xl font-semibold">
              {bookings.length > 0 ? getTodaysBookingsCount() : 0}
            </p>
            <p className="font-light">Today’s Bookings</p>
          </div>
          {isPopup_forBox4 && (
            <>
              <div
                className="overlay_Dashboard"
                onClick={closePopup_forBox4}
              ></div>
              <div className="popup_form_Dashboard">
                <span className="close_Dashboard" onClick={closePopup_forBox4}>
                  &times;
                </span>
                <p>
                  <b>Today's Bookings</b>
                </p>
                <br />
                <form>
                  <div>
                    <Table
                      columns={Columns_forBox4}
                      dataSource={data_forBox4}
                      scroll={{ y: 400 }}
                      pagination={false}
                    />
                    <br />
                    <br />
                  </div>
                </form>
              </div>
            </>
          )}
        </div>

        {userRole !== "Management" && (
          <div className="flex flex-col md:flex-row justify-between m-3 mb-8 gap-5 overflow-x-auto overflow-y-hidden">
            <div className="flex-1 p-3 h-[450px] rounded-sm bg-[#f7f7f7]">
              <div className="flex justify-between">
                <div className="inline-block px-1 rounded-lg outline-none border">
                  <input
                    type="date"
                    value={startDate_donutChart}
                    onChange={(e) => setStartDate_donutChart(e.target.value)}
                    className="text-sm w-[99px] bg-transparent"
                  />
                  <span className="mx-1">-</span>
                  <input
                    type="date"
                    value={endDate_donutChart}
                    onChange={(e) => setEndDate_donutChart(e.target.value)}
                    className="text-sm w-[100px] bg-transparent"
                  />
                </div>

                {/* Donut Chart Dropdown */}
                <div className="flex gap-1 items-center justify-center border px-1 py-0.5 rounded-lg">
                  <select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="inline-block text-sm bg-transparent outline-none"
                    style={{ appearance: "none" }}
                  >
                    <option value="Most Popular Villa">
                      Most Popular Villa
                    </option>
                    <option value="Booking Type">Booking Type</option>
                    <option value="Revenue">Revenue</option>
                  </select>

                  <IoIosArrowDown />
                </div>
              </div>

              {/* <p className="flex justify-center text-center mt-8 -mb-10 text-sm text-gray-600 font-semibold">
                {selectedCategory}
              </p> */}

              <Doughnut data={donutData} options={donutOptions} />
            </div>

            <div className="flex-1 p-3 h-[450px] rounded-sm bg-[#f7f7f7]">
              <div className="inline-block border px-1 py-0.5 rounded-lg">
                <div className="flex gap-1 items-center">
                  <select
                    className="text-sm bg-transparent outline-none"
                    style={{ appearance: "none" }}
                    name="role"
                    value={selectedYear_barChart}
                    onChange={(e) =>
                      setSelectedYear_barChart(Number(e.target.value))
                    }
                    required
                  >
                    {availableYears_barChart.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>

                  <IoIosArrowDown />
                </div>
              </div>
              <div className="flex items-center h-[400px]">
                <Bar data={barData} options={barOptions} />
              </div>
            </div>
          </div>
        )}

        <div className="lg:hidden">
          <div className="flex justify-end items-center mt-[0.5%] mx-[3%] md:mx-[1%]">
            <div className="mr-2">
              <p className="text-xs">Check-In</p>
              <input
                type="date"
                className="text-xs p-[1%] px-[3%] h-[30px] bg-white w-24 lg:w-full rounded-lg outline-none border border-[#444444] cursor-pointer"
                value={checkInDate}
                onChange={(e) => setCheckInDate(e.target.value)}
              />
            </div>

            <div className="mr-2">
              <p className="text-xs">Check-Out</p>
              <input
                type="date"
                className="text-xs p-[1%] px-[3%] h-[30px] bg-white w-24 lg:w-full rounded-lg outline-none border border-[#444444] cursor-pointer"
                value={checkOutDate}
                onChange={(e) => setCheckOutDate(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-between lg:items-center mx-[3%] md:mx-[1%] my-[2%]">
          <div className="flex items-center">
            <p className="font-semibold">List of Bookings</p>
            {selectedRows.length > 0 && (
              <button
                onClick={openPopup_forDelete}
                className="flex items-center ml-5 text-xs p-[1%] px-[3%] h-[30px] bg-white hover:bg-[#877D6F] hover:text-white w-auto lg:w-32 justify-center rounded-lg outline-none border border-[#444444] hover:border-transparent cursor-pointer"
              >
                Delete&nbsp;{selectedRows.length}&nbsp;booking
                {selectedRows.length > 1 ? "s" : ""}
              </button>
            )}
            {isPopup_forDelete && (
              <>
                <div
                  className="overlay_Delete"
                  onClick={closePopup_forDelete}
                ></div>
                <div className="popup_form_Delete">
                  <span className="close_Delete" onClick={closePopup_forDelete}>
                    &times;
                  </span>
                  <form>
                    <div className="">
                      <p>Are you sure you want to delete items?</p>
                      <div className="flex mt-[3%] lg:mt-[5%]">
                        <button
                          type="button"
                          className="cancel_btn"
                          onClick={closePopup_forDelete}
                        >
                          &nbsp;No&nbsp;
                        </button>

                        <button className="done_btn" onClick={handleDeleteRows}>
                          Yes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>

          <div className="flex my-[2%] lg:my-0">
            <div className="mr-2 p-2 px-5 overflow-hidden w-[50px] h-[35px] hover:w-[250px] bg-white shadow-sm rounded-lg flex group items-center hover:duration-300 duration-300 cursor-pointer">
              <div className="flex items-center justify-center fill-[#444444]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Isolation_Mode"
                  data-name="Isolation Mode"
                  viewBox="0 0 24 24"
                  width="12"
                  height="12"
                >
                  <path d="M18.9,16.776A10.539,10.539,0,1,0,16.776,18.9l5.1,5.1L24,21.88ZM10.5,18A7.5,7.5,0,1,1,18,10.5,7.507,7.507,0,0,1,10.5,18Z"></path>
                </svg>
              </div>
              <input
                type="text"
                className="outline-none text-xs bg-transparent w-full text-gray-500 font-normal px-4"
                placeholder="Search here..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <div className="hidden lg:block mx-2 -mt-3.5">
              <p className="text-xs">Check-In</p>
              <input
                type="date"
                className="text-xs p-[1%] px-[3%] h-[30px] bg-white w-24 lg:w-full rounded-lg outline-none border border-[#444444] cursor-pointer"
                value={checkInDate}
                onChange={(e) => setCheckInDate(e.target.value)}
              />
            </div>

            <div className="hidden lg:block mr-1 -mt-3.5">
              <p className="text-xs">Check-Out</p>
              <input
                type="date"
                className="text-xs p-[1%] px-[3%] h-[30px] bg-white w-24 lg:w-full rounded-lg outline-none border border-[#444444] cursor-pointer"
                value={checkOutDate}
                onChange={(e) => setCheckOutDate(e.target.value)}
              />
            </div>

            <button
              className="download_btn ml-2"
              onClick={() => handleExport()}
            >
              <svg
                className="svgIcon"
                viewBox="0 0 384 512"
                height="1rem"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
              </svg>
              <span className="icon2"></span>
              <span className="tooltip">Download</span>
            </button>
          </div>
        </div>

        {/*<div className="flex lg:ml-auto items-center gap-2 w-48 mb-[2%] ml-3 mr-[1.5%]">
           <a
            href="/InquiryForm"
            className="flex items-center justify-center text-xs p-[1%] px-[3%] h-[30px] bg-white w-24 lg:w-full rounded-lg outline-none border border-[#444444] hover:bg-[#877d6f] hover:text-white cursor-pointer"
          >
            Inquiry&nbsp;Form
          </a>

          <a
            href="/FeedbackForm"
            className="flex items-center justify-center text-xs p-[1%] px-[3%] h-[30px] bg-white w-24 lg:w-full rounded-lg outline-none border border-[#444444] hover:bg-[#877d6f] hover:text-white cursor-pointer"
          >
            Feedback&nbsp;Form
          </a> 
        </div>*/}

        <div className="mx-[2%] md:mx-[1%] overflow-x-auto">
          <table className="w-full border-collapse text-[#7C7C7C] text-[14px] 2xl:text-[18px]">
            <thead className="bg-[#E7ECEB]">
              <tr>
                {tableTitles.map((tableTitle, index) => (
                  <th key={index} className="border-b p-2 text-left">
                    {tableTitle}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-[#F7F7F7]"}`}
                >
                  <td className="p-2 text-center">
                    <input
                      type="checkbox"
                      className="customCheckbox cursor-pointer"
                      checked={isRowSelected(row.id, currentPage)}
                      onChange={() => handleCheckboxChange(row.id, currentPage)}
                    />
                  </td>
                  <td className="p-2">{row.id}</td>
                  <td className="p-2">{row.name}</td>
                  <td className="p-2">{row.email}</td>
                  <td className="p-2">{row.villaName}</td>
                  <td className="p-2">
                    {new Date(row.checkIn).toLocaleDateString("en-IN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>
                  <td className="p-2">
                    {new Date(row.checkOut).toLocaleDateString("en-IN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>
                  <td className="p-2">{row.total}</td>
                  <td className="p-2" style={getStatusCellStyle(row.status)}>
                    {row.payment_status}
                  </td>
                  <td className="p-2">
                    {new Date(row.createdAt).toLocaleDateString("en-IN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>
                  <td className="p-2">{row.booking_portal}</td>

                  <td className="p-2 relative">
                    <button
                      onClick={() => openOptions(row.id)}
                      className="text-[19px] hover:scale-110"
                    >
                      <BsThreeDotsVertical />
                    </button>

                    {openRowId === row.id && (
                      <div
                        ref={dropdownRef}
                        className="absolute right-10 -mt-2 w-36 bg-white border shadow-lg rounded-sm text-gray-600 z-10"
                      >
                        {userRole === "Management" ? (
                          <>
                            <button
                              onClick={() => handleFeedbackForm(row)}
                              className="flex items-center w-full px-2 py-0.5 hover:bg-gray-100"
                            >
                              <FaWpforms className="mr-2" />
                              Feedback Form
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={() => handleEditBooking(row)}
                              className="flex items-center w-full px-2 py-0.5 hover:bg-gray-100"
                            >
                              <HiOutlineInformationCircle className="mr-2" />
                              Edit Booking
                            </button>

                            {row.email && (
                              <button
                                onClick={() => handleEmail(row.id)}
                                className="flex items-center w-full px-2 py-0.5 hover:bg-gray-100"
                                disabled={loading}
                              >
                                <TbMailShare className="mr-2" />
                                {loading ? "Sending..." : "Send Email"}
                              </button>
                            )}

                            <button
                              onClick={() => handleFeedbackForm(row)}
                              className="flex items-center w-full px-2 py-0.5 hover:bg-gray-100"
                            >
                              <FaWpforms className="mr-2" />
                              Feedback Form
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Pagination
          rowsPerPage={rowsPerPage}
          totalRows={bookings.length}
          paginate={paginate}
          currentPage={currentPage}
        />

        {isHandle_Delete && (
          <>
            <div className="overlay_Delete" onClick={closeForm_Delete}></div>
            <div className="popup_form_Delete">
              <span className="close_Delete" onClick={closeForm_Delete}>
                &times;
              </span>
              <form>
                <div>
                  <p className="text-white mb-[5%]">
                    Are you sure you want to delete this?
                  </p>
                  <button className="bg-white text-[#877D6F] rounded-lg p-[1%] w-[20%] hover:scale-90 mr-[2%]">
                    Cancel
                  </button>
                  <button className="bg-[#877D6F] text-white rounded-lg p-[1%] w-[20%] hover:scale-90">
                    Yes
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
