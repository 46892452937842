import React, { useState } from "react";
import Menu from "../Menu/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../Config";

const EditUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userDetails = location.state?.userDetails || "";

  const [formData, setFormData] = useState({
    id: userDetails.id || "",
    firstName: userDetails.firstName || "",
    lastName: userDetails.lastName || "",
    email: userDetails.email || "",
    phone: userDetails.phone || "",
    address: userDetails.address || "",
    postcode: userDetails.postcode || "",
    country: userDetails.country || "",
    city: userDetails.city || "",
    password: userDetails.password || "",
    role: userDetails.role || "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const numericValue = value.replace(/\D/g, "");
      const truncatedValue = numericValue.slice(0, 10);
      setFormData({
        ...formData,
        [name]: truncatedValue,
      });
    } else if (name === "postcode") {
      if (/^\d{0,6}$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const updateUser = async () => {
    try {
      const response = await axios.put(
        API_URL + `/updateuser?email=${formData.email}`,
        formData
      );

      if (response.status === 200) {
        window.alert("user updated successfully!");
        navigate("/UserDatabase");
      } else {
        console.error("Error updating user");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10">
      <Menu />
      <div className="lg:ml-[8%] p-[3%] md:p-[2%]">
        <p className="mb-[2%] font-semibold">Edit User</p>

        <div className="grid grid-cols-1 lg:grid-cols-3 items-center">
          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              First Name
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Last Name
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Email
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              readOnly
              disabled
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Phone Number
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Address
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Post Code/ ZIP
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="postcode"
              value={formData.postcode}
              onChange={handleInputChange}
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Country
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">City</p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Any Special Request</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='email' value={formData.email} onChange={handleInputChange} />
                    </div> */}

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Password
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">Role</p>
            <select
              className="bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="role"
              value={formData.role}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled hidden name="payment_status">
                Select
              </option>
              <option value="User">User</option>
              <option value="Agent">Agent</option>
              <option value="Admin">Admin</option>
              <option value="Management">Management</option>
            </select>
          </div>
        </div>

        <div className="flex my-[3%] lg:my-[1%] ml-[2%]">
          <button
            className="cancel_btn"
            onClick={() => navigate("/UserDatabase")}
          >
            Cancel
          </button>

          <button className="done_btn" onClick={() => updateUser()}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
