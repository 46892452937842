import React, { useEffect, useState } from "react";
import "../Dashboard/Dashboard.css";
import Menu from "../Menu/Menu";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../Config";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const FeedbackHistory = () => {
  const navigate = useNavigate();
  const [isHandle_Delete, setHandle_Delete] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [feedbackData, setFeedbackData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("LearnOfSoham");
  const [categoryOptions, setCategoryOptions] = useState({});

  useEffect(() => {
    const fetchFeedbackData = async () => {
      try {
        const response = await fetch(`${API_URL}/feedbackController`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`HTTP ${response.status}: ${errorText}`);
        }

        const data = await response.json();

        if (!Array.isArray(data) || data.length === 0) {
          console.warn("No feedback data received.");
          return;
        }

        setFeedbackData(data);
        const extractedCategories = extractCategories(data);
        setCategoryOptions(extractedCategories);
        setSelectedCategory(
          Object.keys(extractedCategories).includes("LearnOfSoham")
            ? "LearnOfSoham"
            : Object.keys(extractedCategories)[0] || ""
        );

        const today = new Date().toISOString().split("T")[0];
        const filteredFeedback = data.filter(
          (feedback) => feedback.timestamps <= today
        );

        setFeedbacks(filteredFeedback);
        localStorage.setItem("feedback", JSON.stringify(filteredFeedback));
      } catch (error) {
        console.error("Error fetching feedback:", error);
      }
    };

    fetchFeedbackData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const closeForm_Delete = () => {
    setHandle_Delete(false);
  };

  const tableTitles = [
    " ",
    "ID",
    "Name",
    "Contact No.",
    "Email",
    "Place Of Residence",
    "First Experience",
    "Source of Discovery",
    "Stayed In",
    "Rating",
    "Date",
    "Action",
  ];

  const filteredFeedback = feedbacks
    .filter((i) => {
      const searchQueryLower = searchQuery.toLowerCase();
      const feedbackDate = i.timestamps ? i.timestamps.split("T")[0] : "";

      i.id = i.id ? i.id.toString() : "";
      i.name = i.name ? i.name.toString() : "";
      i.contactNumber = i.contactNumber ? i.contactNumber.toString() : "";
      i.emailId = i.emailId ? i.emailId.toString() : "";
      i.placeOfResidence = i.placeOfResidence
        ? i.placeOfResidence.toString()
        : "";
      i.FirstExperince = i.FirstExperince ? i.FirstExperince.toString() : "";
      i.LearnOfSoham = i.LearnOfSoham ? i.LearnOfSoham.toString() : "";
      i.YouStayedIn = i.YouStayedIn ? i.YouStayedIn.toString() : "";
      i.rateTheOverallStay = i.rateTheOverallStay
        ? i.rateTheOverallStay.toString()
        : "";
      i.recommend = i.recommend ? i.recommend.toString() : "";
      i.timestamps = i.timestamps ? i.timestamps.toString() : "";

      return (
        (searchQuery === "" ||
          (i.id && i.id.toString().includes(searchQueryLower)) ||
          (i.name && i.name.toLowerCase().includes(searchQueryLower)) ||
          (i.contactNumber &&
            i.contactNumber
              .toString()
              .toLowerCase()
              .includes(searchQueryLower)) ||
          (i.emailId && i.emailId.toLowerCase().includes(searchQueryLower)) ||
          (i.placeOfResidence &&
            i.placeOfResidence.toLowerCase().includes(searchQueryLower)) ||
          (i.FirstExperince &&
            i.FirstExperince.toLowerCase().includes(searchQueryLower)) ||
          (i.LearnOfSoham &&
            i.LearnOfSoham.toLowerCase().includes(searchQueryLower)) ||
          (i.YouStayedIn &&
            i.YouStayedIn.toLowerCase().includes(searchQueryLower)) ||
          (i.rateTheOverallStay &&
            i.rateTheOverallStay.toLowerCase().includes(searchQueryLower)) ||
          (i.recommend &&
            i.recommend.toLowerCase().includes(searchQueryLower)) ||
          (i.timestamps &&
            i.timestamps.toLowerCase().includes(searchQueryLower))) &&
        feedbackDate <= new Date().toISOString().split("T")[0]
      );
    })
    .sort((a, b) => {
      const today = new Date().toISOString().split("T")[0];

      const dateA = a.timestamps ? a.timestamps.split("T")[0] : "";
      const dateB = b.timestamps ? b.timestamps.split("T")[0] : "";

      if (dateA === today) return -1;
      if (dateB === today) return 1;

      return dateB.localeCompare(dateA);
    });

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 20;

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows =
    filteredFeedback.length > 0
      ? filteredFeedback.slice(indexOfFirstRow, indexOfLastRow)
      : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const Pagination = ({ rowsPerPage, totalRows, paginate, currentPage }) => {
    const totalPages = Math.ceil(totalRows / rowsPerPage);

    return (
      <nav className="flex justify-end my-[2%]">
        <ul className="flex items-center">
          {currentPage > 1 && (
            <li className="mx-1">
              <button
                onClick={() => paginate(currentPage - 1)}
                className="p-1 bg-gray-200 text-xs rounded-full"
              >
                <MdKeyboardArrowLeft />
              </button>
            </li>
          )}
          <li className="mx-1">
            <span className="px-2 py-2 text-xs">
              {currentPage} / {totalPages}
            </span>
          </li>
          {currentPage < totalPages && (
            <li className="mx-1">
              <button
                onClick={() => paginate(currentPage + 1)}
                className="p-1 bg-gray-200 text-xs rounded-full"
              >
                <MdKeyboardArrowRight />
              </button>
            </li>
          )}
        </ul>
      </nav>
    );
  };

  const [fileName, setFileName] = useState("Total Revenue");
  const handleExport = () => {
    const userInput = window.prompt("Enter file name:", "Total Revenue");
    if (userInput) {
      setFileName(userInput.trim());
      handleExportConfirm();
    }
  };

  const handleExportConfirm = () => {
    const exportData = currentRows;

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });

    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    saveAs(blob, `${fileName}.xlsx`);
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const handleEditFeedbackForm = (row) => {
    navigate("/EditFeedbackForm", { state: { feedbackDetails: row } });
  };

  // const [loading, setLoading] = useState(false);

  // const handleEmail = (id) => {
  //   const numericId = Number(id);
  //   setLoading(true);

  //   setTimeout(() => {
  //     fetch(API_URL + "/send-email", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ id: numericId }),
  //     })
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error(`HTTP error! status: ${response.status}`);
  //         }
  //         return response.json();
  //       })
  //       .then((data) => {
  //         setLoading(false);
  //         setTimeout(() => alert("Email sent successfully!"), 0);
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //         alert("Failed to send email.");
  //         setLoading(false);
  //       });
  //   }, 0);
  // };

  const [selectedRows, setSelectedRows] = useState([]);

  const handleCheckboxChange = (id, page) => {
    setSelectedRows((prevSelected) => {
      if (prevSelected.some((row) => row.id === id && row.page === page)) {
        return prevSelected.filter(
          (row) => !(row.id === id && row.page === page)
        );
      } else {
        return [...prevSelected, { id, page }];
      }
    });
  };

  const isRowSelected = (id, page) => {
    return selectedRows.some((row) => row.id === id && row.page === page);
  };

  const handleDeleteRows = async (event) => {
    event.preventDefault();

    const rowsToDelete = selectedRows.map((row) => row.id);

    setTimeout(async () => {
      try {
        const response = await fetch(
          `${API_URL}/deleteFeedbackController?id=${rowsToDelete.join(",")}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          alert(`Deleted feedback successfully: ${rowsToDelete.join(", ")}`);
          window.location.reload();
        } else {
          alert("Failed to delete feedback.");
        }
      } catch (error) {
        console.error("Error deleting rows:", error);
      }
    });
  };

  const [isPopup_forDelete, setPopup_forDelete] = useState(false);

  const openPopup_forDelete = () => {
    setPopup_forDelete(true);
  };

  const closePopup_forDelete = () => {
    setPopup_forDelete(false);
  };

  const excludedFields = [
    "id",
    "timestamps",
    "name",
    "dateOfBirth",
    "contactNumber",
    "emailId",
    "suggestions",
  ];

  const extractCategories = (data) => {
    const categories = {};

    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (!excludedFields.includes(key)) {
          if (!categories[key]) {
            categories[key] = new Set();
          }
          categories[key].add(item[key]);
        }
      });
    });

    const formattedCategories = {};
    Object.keys(categories).forEach((key) => {
      formattedCategories[key] = Array.from(categories[key]);
    });

    return formattedCategories;
  };

  const processData = () => {
    const selectedOptions = categoryOptions[selectedCategory] || [];
    const counts = selectedOptions.reduce((acc, option) => {
      acc[option] = 0;
      return acc;
    }, {});

    feedbackData.forEach((item) => {
      if (counts.hasOwnProperty(item[selectedCategory])) {
        counts[item[selectedCategory]] += 1;
      }
    });

    const totalResponses = feedbackData.length || 1; // Prevent division by zero
    const percentages = selectedOptions.map((option) =>
      ((counts[option] / totalResponses) * 100).toFixed(2)
    );

    return { labels: selectedOptions, data: percentages };
  };

  const chartData = processData();

  const labelMapping = {
    placeOfResidence: "Place of Residence",
    FirstExperince: "First Experience",
    LearnOfSoham: "Source of Discovery",
    ReservationHandled: "Reservation Handling",
    YouStayedIn: "Stayed Villa",
    cleanlinessHygiene: "Cleanliness & Hygiene",
    housekeepingService: "Housekeeping Service",
    caretakersFriendliness: "Caretaker's Friendliness",
    caretakersCompetence: "Caretaker's Competence",
    qualityOfTheServicesProvided: "Quality of Services",
    villaAmbience: "Villa Ambience",
    villaLocation: "Villa Location",
    rateTheOverallStay: "Overall Stay Rating",
    recommend: "Recommend",
  };

  const getFormattedLabel = (key) => labelMapping[key] || key;

  const barData = {
    labels:
      categoryOptions[selectedCategory]?.map((item) =>
        getFormattedLabel(item)
      ) || [],
    datasets: [
      {
        label: getFormattedLabel(selectedCategory),
        data: chartData.data,
        backgroundColor: [
          "#E8B4B4",
          "#F3D89C",
          "#A9D3A5",
          "#7BB5B2",
          "#82B1D1",
          "#9D91C8",
          "#B99ACD",
          "#D5A3B6",
          "#C2A18B",
          "#D6BDA2",
          "#D89C9C",
          "#DFC3A4",
        ],
        hoverBackgroundColor: [
          "#E8B4B4",
          "#F3D89C",
          "#A9D3A5",
          "#7BB5B2",
          "#82B1D1",
          "#9D91C8",
          "#B99ACD",
          "#D5A3B6",
          "#C2A18B",
          "#D6BDA2",
          "#D89C9C",
          "#DFC3A4",
        ],
        borderRadius: 5,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      title: { display: true, text: "Analysis" },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.raw}%`;
          },
        },
      },
    },
    scales: {
      y: {
        // min: 0,
        // max: 100,
        ticks: { callback: (value) => value + "%" },
        grid: { display: false },
      },
      x: { grid: { display: false } },
    },
  };

  return (
    <div className="text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10">
      <Menu />
      <div className="lg:ml-[8%] p-[3%] md:p-[2%]">
        {/* Chart */}
        <div className="overflow-x-auto">
          <div className="flex-1 mx-[1%] mb-[3%] p-5 pb-10 h-[500px] w-[150%] md:w-auto rounded-sm bg-[#f7f7f7] flex flex-col justify-center items-center">
            <div className="flex flex-col mr-auto items-start mt-[2%]">
              <select
                className="bg-transparent text-sm border-[2px] p-[1%] h-[35px] w-auto rounded-lg outline-none text-left"
                required
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                {Object.keys(categoryOptions).map((key) => (
                  <option key={key} value={key}>
                    {labelMapping[key] || key}
                  </option>
                ))}
              </select>
            </div>

            <Bar data={barData} options={chartOptions} />
          </div>
        </div>

        <div className="lg:hidden">
          <div className="flex justify-end items-center mx-[3%] md:mx-[1%]">
            <div className="mr-2">
              <p className="text-xs">Date</p>
              <input
                type="date"
                className="text-xs p-[1%] px-[3%] h-[30px] bg-white w-24 lg:w-full rounded-lg outline-none border border-[#444444] cursor-pointer"
                // onChange={(e) => setCheckInDate(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-between lg:items-center mx-[3%] md:mx-[1%] mb-[2%]">
          <div className="flex items-center">
            <p className="font-semibold">Feedback Form History</p>
            {selectedRows.length > 0 && (
              <button
                onClick={openPopup_forDelete}
                className="flex items-center ml-5 text-xs p-[1%] px-[3%] h-[30px] bg-white hover:bg-[#877D6F] hover:text-white w-auto lg:w-32 justify-center rounded-lg outline-none border border-[#444444] hover:border-transparent cursor-pointer"
              >
                Delete&nbsp;{selectedRows.length}&nbsp;form
                {selectedRows.length > 1 ? "s" : ""}
              </button>
            )}
            {isPopup_forDelete && (
              <>
                <div
                  className="overlay_Delete"
                  onClick={closePopup_forDelete}
                ></div>
                <div className="popup_form_Delete">
                  <span className="close_Delete" onClick={closePopup_forDelete}>
                    &times;
                  </span>
                  <form>
                    <div className="">
                      <p>Are you sure you want to delete items?</p>
                      <div className="flex mt-[3%] lg:mt-[5%]">
                        <button
                          type="button"
                          className="cancel_btn"
                          onClick={closePopup_forDelete}
                        >
                          &nbsp;No&nbsp;
                        </button>

                        <button className="done_btn" onClick={handleDeleteRows}>
                          Yes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>

          <div className="flex my-[2%] lg:my-0">
            <div className="mr-2 p-2 px-5 overflow-hidden w-[50px] h-[35px] hover:w-[250px] bg-white shadow-sm rounded-lg flex group items-center hover:duration-300 duration-300 cursor-pointer">
              <div className="flex items-center justify-center fill-[#444444]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Isolation_Mode"
                  data-name="Isolation Mode"
                  viewBox="0 0 24 24"
                  width="12"
                  height="12"
                >
                  <path d="M18.9,16.776A10.539,10.539,0,1,0,16.776,18.9l5.1,5.1L24,21.88ZM10.5,18A7.5,7.5,0,1,1,18,10.5,7.507,7.507,0,0,1,10.5,18Z"></path>
                </svg>
              </div>
              <input
                type="text"
                className="outline-none text-xs bg-transparent w-full text-gray-500 font-normal px-4"
                placeholder="Search here..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <div className="hidden lg:block mx-2 -mt-3.5">
              <p className="text-xs">Date</p>
              <input
                type="date"
                className="text-xs p-[1%] px-[3%] h-[30px] bg-white w-24 lg:w-full rounded-lg outline-none border border-[#444444] cursor-pointer"
                // value={checkInDate}
                // onChange={(e) => setCheckInDate(e.target.value)}
              />
            </div>

            <button
              className="download_btn ml-2"
              onClick={() => handleExport()}
            >
              <svg
                className="svgIcon"
                viewBox="0 0 384 512"
                height="1rem"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
              </svg>
              <span className="icon2"></span>
              <span className="tooltip">Download</span>
            </button>
          </div>
        </div>

        <div className="mx-[2%] md:mx-[1%] overflow-x-auto">
          <table className="w-full border-collapse text-[#7C7C7C] text-[14px] 2xl:text-[18px]">
            <thead className="bg-[#E7ECEB]">
              <tr>
                {tableTitles.map((tableTitle, index) => (
                  <th key={index} className="border-b p-2 text-left">
                    {tableTitle}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-[#F7F7F7]"}`}
                >
                  <td className="p-2 text-center">
                    <input
                      type="checkbox"
                      className="customCheckbox cursor-pointer"
                      checked={isRowSelected(row.id, currentPage)}
                      onChange={() => handleCheckboxChange(row.id, currentPage)}
                    />
                  </td>
                  <td className="p-2">{row.id}</td>
                  <td className="p-2">{row.name}</td>
                  <td className="p-2">{row.contactNumber}</td>
                  <td className="p-2">{row.emailId}</td>
                  <td className="p-2">{row.placeOfResidence}</td>
                  <td className="p-2">{row.FirstExperince}</td>
                  <td className="p-2">{row.LearnOfSoham}</td>
                  <td className="p-2">{row.YouStayedIn}</td>
                  <td className="p-2">{row.rateTheOverallStay}</td>
                  <td className="p-2">
                    {new Date(row.timestamps).toLocaleDateString("en-IN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>
                  <td className="p-2 flex justify-center items-center align-items-center">
                    <button
                      onClick={() => handleEditFeedbackForm(row)}
                      className="text-[19px] hover:scale-110"
                    >
                      <HiOutlineInformationCircle />
                    </button>
                    {/* {row.email ? (
                      loading ? (
                        <>
                          <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-gray-900 bg-opacity-10 z-50">
                            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
                            <p className="text-white mt-3">
                              Sending an Email...
                            </p>
                          </div>
                        </>
                      ) : (
                        <button
                          onClick={() => {
                            handleEmail(row.id);
                          }}
                          className="text-[19px] ml-[20%] hover:scale-110"
                        >
                          <TbMailShare />
                        </button>
                      )
                    ) : null} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Pagination
          rowsPerPage={rowsPerPage}
          totalRows={feedbacks.length}
          paginate={paginate}
          currentPage={currentPage}
        />

        {isHandle_Delete && (
          <>
            <div className="overlay_Delete" onClick={closeForm_Delete}></div>
            <div className="popup_form_Delete">
              <span className="close_Delete" onClick={closeForm_Delete}>
                &times;
              </span>
              <form>
                <div>
                  <p className="text-white mb-[5%]">
                    Are you sure you want to delete this?
                  </p>
                  <button className="bg-white text-[#877D6F] rounded-lg p-[1%] w-[20%] hover:scale-90 mr-[2%]">
                    Cancel
                  </button>
                  <button className="bg-[#877D6F] text-white rounded-lg p-[1%] w-[20%] hover:scale-90">
                    Yes
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FeedbackHistory;
