import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Menu from "./components/Menu/Menu";
import Dashboard from "./components/Dashboard/Dashboard";
import EditBooking from "./components/EditBooking/EditBooking";
import UserDatabase from "./components/UserDatabase/UserDatabase";
import EditUser from "./components/EditUser/EditUser";
import AddUser from "./components/AddUser/AddUser";
import PropertyDatabase from "./components/PropertyDatabase/PropertyDatabase";
import EditProperty from "./components/EditProperty/EditProperty";
import AddProperty from "./components/AddProperty/AddProperty";
import ManualBooking from "./components/ManualBooking/ManualBooking";
import AddManualBooking from "./components/AddManualBooking/AddManualBooking";
import EditManualBooking from "./components/EditManualBooking/EditManualBooking";
import GenerateCoupon from "./components/GenerateCoupon/GenerateCoupon";
import AddCoupon from "./components/AddCoupon/AddCoupon";
import MyCalendar from "./components/MyCalendar/MyCalendar";
import Settings from "./components/Settings/Settings";
import EditCoupon from "./components/EditCoupon/EditCoupon";
import { AuthProvider, useAuth } from "./AuthContext";
import Login from "./components/Login/Login";
// import PricingComponent from './components/PricingComponent';
// import PriceCalendar from './components/PriceCalendar';
// import PriceCalendar from './components/PriceCalendar';
import PricingCalendar from "./components/PricingCalendar/PricingCalendar";
import History from "./components/History/History";
import FeedbackForm from "./components/Forms/FeedbackForm";
import InquiryForm from "./components/Forms/InquiryForm";
import FeedbackHistory from "./components/History/FeedbackHistory";
import InquiryHistory from "./components/History/InquiryHistory";
import EditFeedbackForm from "./components/Forms/EditFeedbackForm";

function App() {
  function ProtectedRoute({ element }) {
    const { user } = useAuth();

    if (user && element.type === Login) {
      return <Navigate to="/Dashboard" />;
    }

    return user ? element : <Navigate to="/Login" />;
  }
  return (
    <div>
      <Router>
        <AuthProvider>
          <Routes>
            <Route
              path="/"
              element={<ProtectedRoute element={<Dashboard />} />}
            />
            <Route
              path="/Login"
              element={<ProtectedRoute element={<Login />} />}
            />
            <Route
              path="/Menu"
              element={<ProtectedRoute element={<Menu />} />}
            />
            <Route
              path="/Dashboard"
              element={<ProtectedRoute element={<Dashboard />} />}
            />
            <Route
              path="/MyCalendar"
              element={<ProtectedRoute element={<MyCalendar />} />}
            />
            <Route
              path="/EditBooking"
              element={<ProtectedRoute element={<EditBooking />} />}
            />
            <Route
              path="/UserDatabase"
              element={<ProtectedRoute element={<UserDatabase />} />}
            />
            <Route
              path="/EditUser"
              element={<ProtectedRoute element={<EditUser />} />}
            />
            <Route
              path="/AddUser"
              element={<ProtectedRoute element={<AddUser />} />}
            />
            <Route
              path="/PropertyDatabase"
              element={<ProtectedRoute element={<PropertyDatabase />} />}
            />
            <Route
              path="/EditProperty"
              element={<ProtectedRoute element={<EditProperty />} />}
            />
            <Route
              path="/AddProperty"
              element={<ProtectedRoute element={<AddProperty />} />}
            />
            <Route
              path="/ManualBooking"
              element={<ProtectedRoute element={<ManualBooking />} />}
            />
            <Route
              path="/AddManualBooking"
              element={<ProtectedRoute element={<AddManualBooking />} />}
            />
            <Route
              path="/EditManualBooking"
              element={<ProtectedRoute element={<EditManualBooking />} />}
            />
            <Route
              path="/GenerateCoupon"
              element={<ProtectedRoute element={<GenerateCoupon />} />}
            />
            <Route
              path="/EditCoupon"
              element={<ProtectedRoute element={<EditCoupon />} />}
            />
            <Route
              path="/AddCoupon"
              element={<ProtectedRoute element={<AddCoupon />} />}
            />
            <Route
              path="/Settings"
              element={<ProtectedRoute element={<Settings />} />}
            />
            {/* <Route path='/PriceCalendar' element={<ProtectedRoute element={<PriceCalendar />} />} /> */}
            <Route
              path="/PricingCalendar"
              element={<ProtectedRoute element={<PricingCalendar />} />}
            />
            <Route
              path="/History"
              element={<ProtectedRoute element={<History />} />}
            />
            <Route
              path="/FeedbackForm"
              element={<ProtectedRoute element={<FeedbackForm />} />}
            />
            <Route
              path="/InquiryForm"
              element={<ProtectedRoute element={<InquiryForm />} />}
            />
            <Route
              path="/FeedbackHistory"
              element={<ProtectedRoute element={<FeedbackHistory />} />}
            />
            <Route
              path="/InquiryHistory"
              element={<ProtectedRoute element={<InquiryHistory />} />}
            />
            <Route
              path="/EditFeedbackForm"
              element={<ProtectedRoute element={<EditFeedbackForm />} />}
            />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
