import React from "react";
import Menu from "../Menu/Menu";

const InquiryForm = () => {
  const timestamp = new Date().toLocaleString();

  return (
    <div className="text-[14px] md:text-lg lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10">
      <Menu />

      <div className="lg:ml-[8%] p-[3%] md:p-[2%]">
        <div className="flex justify-between items-center">
          <p className="mb-[2%] font-semibold">Inquiry Form</p>

          <p className="text-right">Timestamp: {timestamp}</p>
        </div>

        <p className="font-semibold mb-[1%] ml-[2.5%]">Guest Information</p>

        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Full Name
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="name"
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Date Of Birth
            </p>
            <input
              type="date"
              className="bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="dateOfBirth"
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Contact No.
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="contactNo"
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Email
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="email"
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Place Of Residence
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="residence"
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Have you stayed at Soham Villas before?
            </p>
            <select
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>

        <p className="font-semibold my-[1%] ml-[2.5%]">Booking Preferences</p>

        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              What is the purpose of your stay?
            </p>
            <select
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Leisure</option>
              <option>Business</option>
              <option>Family</option>
              <option>Gathering</option>
              <option>Special Occasion</option>
              <option>Other</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Which type of villa are you interested in?
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Preferred Check-in Date
            </p>
            <input
              type="date"
              className="bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="dateOfBirth"
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Preferred Check-out Date
            </p>
            <input
              type="date"
              className="bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="dateOfBirth"
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              How many guests will be staying?
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Do you require any additional services?
            </p>
            <select
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Catering</option>
              <option>Decoration</option>
              <option>Local Tours</option>
              <option>Transportation</option>
              <option>Other</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              What are your top priorities for a villa stay?
            </p>
            <select
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Luxury</option>
              <option>Budget-friendly</option>
              <option>Location</option>
              <option>Ambience</option>
              <option>Services</option>
              <option>Cleanliness</option>
              <option>Privacy</option>
              <option>Other</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Have you stayed in a private villa before?
            </p>
            <select
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              If yes, where?
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Would you like to receive special offers and updates from Soham
              Villas?
            </p>
            <select
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Do you have any special requests or questions?
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            />
          </div>
        </div>

        <p className="font-semibold my-[1%] ml-[2.5%]">
          Inquiry Conversion Tracking
        </p>

        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Was this inquiry converted into a booking?
            </p>
            <select
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              If Yes, which villa was booked?
            </p>
            <select
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>House Of Bashō</option>
              <option>Sattva</option>
              <option>Villa Celebration</option>
              <option>Villa Serenity</option>
              <option>Villa Arcadia</option>
              <option>Villa Luxo</option>
              <option>Villa Mandovi</option>
              <option>Villa Chapora</option>
              <option>Villa Sal</option>
              <option>Villa Zuari</option>
              <option>Aqua Grandiosa - 201</option>
              <option>Villa Vera G-7</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              If No, what was the reason for not booking?
            </p>
            <select
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Price was too high</option>
              <option>Found another property</option>
              <option>Dates were unavailable</option>
              <option>Change in travel plans</option>
              <option>Other (Please specify)</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Any additional comments or feedback from the guest?
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            />
          </div>
        </div>

        <div className="flex my-[3%] lg:my-[1%] ml-[3%]">
          <button className="cancel_btn">Cancel</button>

          <button className="done_btn">Done</button>
        </div>
      </div>
    </div>
  );
};

export default InquiryForm;
