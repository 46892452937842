import React, { useEffect, useState } from "react";
import Menu from "../Menu/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../../Config";

const FeedbackForm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  const { name, phone, email, villaName } = location.state || {};

  const [formData, setFormData] = useState({
    name: name || "",
    dateOfBirth: "",
    contactNo: phone || "",
    email: email || "",
    residence: "",
    firstExperience: "",
    source: "",
    reservationHandling: "",
    stayedVilla: villaName || "",
    overallStay: "",
    cleanlinessHygiene: "",
    housekeepingService: "",
    caretakerFriendliness: "",
    caretakerCompetence: "",
    serviceQuality: "",
    villaAmbience: "",
    villaLocation: "",
    recommend: "",
    suggestions: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const timestamp = new Date().toLocaleString();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch(API_URL + "/addFeedbackController", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (response.ok) {
        alert("Feedback submitted successfully!");
        navigate("/Dashboard");
      } else {
        alert(`Failed to submit feedback: ${result.message}`);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      alert("Failed adding feedback. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="text-[14px] md:text-lg lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10">
      <Menu />

      <div className="lg:ml-[8%] p-[3%] md:p-[2%]">
        <div className="flex justify-between items-center">
          <p className="mb-[2%] font-semibold">Feedback Form</p>

          <p className="text-right text-gray-500">Date & Time: {timestamp}</p>
        </div>
        <form
          className="grid grid-cols-1 lg:grid-cols-2"
          onSubmit={handleSubmit}
        >
          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">Name</p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="name"
              value={formData.name}
              onChange={handleChange}
              disabled={!!formData.name}
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Date Of Birth
            </p>
            <input
              type="date"
              className="bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Contact No.
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="contactNo"
              value={formData.contactNo}
              onChange={handleChange}
              disabled={!!formData.contactNo}
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Email
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="email"
              value={formData.email}
              onChange={handleChange}
              disabled={!!formData.email}
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Place Of Residence
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="residence"
              value={formData.residence}
              onChange={handleChange}
              placeholder="City, State"
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Was this your first experience with Soham Villas?
            </p>
            <select
              name="firstExperience"
              value={formData.firstExperience}
              onChange={handleChange}
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              How did you learn of Soham Villas?
            </p>
            <select
              name="source"
              value={formData.source}
              onChange={handleChange}
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Social Media</option>
              <option>Friends</option>
              <option>Family</option>
              <option>Online Ads</option>
              <option>Travel Website</option>
              <option>Other</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              How was your Reservation Handled?
            </p>
            <select
              name="reservationHandling"
              value={formData.reservationHandling}
              onChange={handleChange}
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Excellent</option>
              <option>Good</option>
              <option>Average</option>
              <option>Poor</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Villa you stayed in?
            </p>
            <input
              type="text"
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
              name="stayedVilla"
              value={formData.stayedVilla}
              onChange={handleChange}
              disabled={!!formData.stayedVilla}
            />
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              How would you rate the overall stay?
            </p>
            <select
              name="overallStay"
              value={formData.overallStay}
              onChange={handleChange}
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Excellent</option>
              <option>Good</option>
              <option>Average</option>
              <option>Poor</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4 mb-[1%]">
              How would you rate your experience with the listed aspects at
              Soham Villas? [Cleanliness & Hygiene]
            </p>
            <select
              name="cleanlinessHygiene"
              value={formData.cleanlinessHygiene}
              onChange={handleChange}
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Excellent</option>
              <option>Good</option>
              <option>Average</option>
              <option>Poor</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4 mb-[1%]">
              How would you rate your experience with the listed aspects at
              Soham Villas? [Housekeeping Service]
            </p>
            <select
              name="housekeepingService"
              value={formData.housekeepingService}
              onChange={handleChange}
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Excellent</option>
              <option>Good</option>
              <option>Average</option>
              <option>Poor</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4 mb-[1%]">
              How would you rate your experience with the listed aspects at
              Soham Villas? [Caretaker's Friendliness]
            </p>
            <select
              name="caretakerFriendliness"
              value={formData.caretakerFriendliness}
              onChange={handleChange}
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Excellent</option>
              <option>Good</option>
              <option>Average</option>
              <option>Poor</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4 mb-[1%]">
              How would you rate your experience with the listed aspects at
              Soham Villas? [Caretaker's Competence]
            </p>
            <select
              name="caretakerCompetence"
              value={formData.caretakerCompetence}
              onChange={handleChange}
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Excellent</option>
              <option>Good</option>
              <option>Average</option>
              <option>Poor</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4 mb-[1%]">
              How would you rate your experience with the listed aspects at
              Soham Villas? [Quality of the Services Provided]
            </p>
            <select
              name="serviceQuality"
              value={formData.serviceQuality}
              onChange={handleChange}
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Excellent</option>
              <option>Good</option>
              <option>Average</option>
              <option>Poor</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4 mb-[1%]">
              How would you rate your experience with the listed aspects at
              Soham Villas? [Villa Ambience]
            </p>
            <select
              name="villaAmbience"
              value={formData.villaAmbience}
              onChange={handleChange}
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Excellent</option>
              <option>Good</option>
              <option>Average</option>
              <option>Poor</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4 mb-[1%]">
              How would you rate your experience with the listed aspects at
              Soham Villas? [Villa Location]
            </p>
            <select
              name="villaLocation"
              value={formData.villaLocation}
              onChange={handleChange}
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Excellent</option>
              <option>Good</option>
              <option>Average</option>
              <option>Poor</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4 mb-[1%]">
              Would you recommend Soham Villas to friends or family?
            </p>
            <select
              name="recommend"
              value={formData.recommend}
              onChange={handleChange}
              style={{ appearance: "none" }}
              className="bg-white border-[2px] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>

          <div className="m-[2%] lg:mx-[5%]">
            <p className="text-[12px] md:text-sm 2xl:text-lg leading-4">
              Do you have any suggestions for improvements or areas that could
              be enhanced?
            </p>
            <input
              type="text"
              name="suggestions"
              value={formData.suggestions}
              onChange={handleChange}
              className="border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md"
            />
          </div>
        </form>

        <div className="flex my-[3%] lg:my-[1%] ml-[3%]">
          <button
            className="cancel_btn"
            onClick={() => navigate("/Dashboard")}
            disabled={isSubmitting}
          >
            Cancel
          </button>

          <button className="done_btn" onSubmit={handleSubmit}>
            {isSubmitting ? "Submitting..." : "Done"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackForm;
